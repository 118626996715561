import { createContext, useContext } from 'react';
export const NonceContext = createContext<string | undefined>(undefined);
export const NonceProvider = NonceContext.Provider;

/*
 * Will return empty client-side as nonce should not be available in the client.
 *
 * This is because the browser clears the nonce before javascript can run, so when the client hydrates nonce is empty.
 * Passing empty string as the nonce client-side will match what the browser had done, requiring it to be defaulted to empty string to avoid a hydration mismatch.
 *
 * Passing the nonce to the client is not advised as it can allow browser extensions access to it.
 * https://github.com/kentcdodds/nonce-hydration-issues
 * */
export const useNonce = () => useContext(NonceContext) ?? '';
